<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      timer: null,
    }
  },
  created () {
  },
  methods: {
    
  }
}
</script>

<style>


</style>
