<template>
<div style="padding-top:var(--van-nav-bar-height);">
  <van-sticky>
  <van-nav-bar title="智聯兼職" :fixed="true" />
  </van-sticky>
  <van-swipe :autoplay="3000" lazy-render>
    <van-swipe-item v-for="image in images" :key="image">
      <img :src="image" width="100%" style="max-height:250px">
    </van-swipe-item>
  
  </van-swipe>   
  <div style="padding: 10px;">
    <van-row gutter="10">
      <van-col span="12">
        <div class="title_desc">
          <van-grid :column-num="2" :border="false">
            <van-grid-item icon="photo-o">
            周末兼职
            简单好上手
            </van-grid-item>
            <van-grid-item icon="photo-o">
                <van-icon name="notes-o" color="#1989fa" size=50 />
            </van-grid-item>
            </van-grid>
          
        </div>
      </van-col>
      <van-col span="12">
        <div class="title_desc">
          <van-grid :column-num="2" :border="false">
            <van-grid-item icon="photo-o">
            薪资日结
            靠谱有保障
            </van-grid-item>
            <van-grid-item icon="photo-o">
                <van-icon name="notes-o" color="#1989fa" size=50 />
            </van-grid-item>
            </van-grid>
          
        </div>
      </van-col>
    </van-row>
  </div>
  <div class="title">前沿发布</div>
      <div class="item" v-for="(r,i) in data" :key="i">
        <van-row gutter="10">
          <van-col span="4">
            <van-image
              round
              width="3rem"
              height="3rem"
              :src="r.thumb"
            />
          </van-col>
          <van-col span="20">
            <div class="detail">
              <h3>{{r.title}}</h3>
              <span class="time">{{r.time}}</span>
              <span class="money">佣金:{{r.money}}新臺幣</span>
              <span class="tags"><van-tag color="#1989fa">#周末兼职#</van-tag></span>
              <p>{{r.desc}}</p>
              
            </div>
          </van-col>
        </van-row>
      </div>
  
  <div class="btn-bottom">
    <van-button @click="openChat" type="primary" size="large">點撃聯係在綫客服</van-button>
  </div>
</div> 
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'Home',
  components: {
  },

  setup() {
    const active = ref(0);
     const images = [
      'https://www.tinderme.top/imgs/1.png',
      'https://www.tinderme.top/imgs/2.png',
    ];
    const data = [
      {
        'thumb' : 'https://www.tinderme.top/imgs/a1.png',
        'title' : '快手極速注冊',
        'desc' : '下載快手極速版APP 注冊成功，并且實名認證之後截圖發給客服領取佣金。（具體要求聯係客服）', 
        'time' : '2024/5/5 9:50',
        'money' : '40'
      },
      {
        'thumb' : 'https://www.tinderme.top/imgs/a2.png',
        'title' : '抖音極速版注冊',
        'desc' : '下載快手極速版APP 注冊成功，并且實名認證之後截圖發給客服領取佣金（具體要求聯係客服咨詢）', 
        'time' : '2024/4/3 16:35',
        'money' : '40'
      },

      {
        'thumb' : 'https://www.tinderme.top/imgs/a3.png',
        'title' : 'Vinder注冊',
        'desc' : '下載Vinder App 注冊認證成功,實名認證后聯係客服領取本金+佣金（具體需求聯係客服咨詢）', 
        'time' : '2024/6/10 13:57',
        'money' : '150'
      },

      {
        'thumb' : 'https://www.tinderme.top/imgs/a4.png',
        'title' : '蝦皮極速版測評',
        'desc' : '下載蝦皮App 注冊成功之後，購買指定的商品 截圖發給客服領取本金+佣金（具體要求聯係客服咨詢）', 
        'time' : '2024/5/3 10:21',
        'money' : '150'
      },
 
      {
        'thumb' : 'https://www.tinderme.top/imgs/a5.png',
        'title' : '淘寶特價版測評',
        'desc' : '下載淘寶特價版app 注冊成功之後，購買指定的商品 截圖發給客服領取本金+佣金（具體咨詢客服）', 
        'time' : '2024/3/27 19:57',
        'money' : '150'
      }



    ];
    return { images, active, data};      
  },
  data () {
    return {
      
    }
  },
  created () {
  },
  methods: {
      openChat () {
        window.open('https://vo.tinderme.top/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=1&groupid=1&special=1')
        //window.open('https://tawk.to/chat/666be1c5981b6c56477d0497/1i0amjrcn')
      } 
  }
}
</script>

<style>
 .my-swipe .van-swipe-item {
    margin-top:10px;
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
</style>
